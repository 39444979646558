import { IntBoolean } from '@/services/api';
import { BaseEntity } from './BaseEntity';
import { ChannelCampaign } from './ChannelCampaign';

export enum ChannelType {
  CPA = 'cpa',
  CPS = 'cps',
  CPC = 'cpc',
  CPM = 'cpm',
  SOCIAL = 'social'
}

interface Rules {
  id: number
  renewalTimes: number
  percentage: number
}

export interface ChannelCommissions {
  id: number
  startDate: string
  stage: number
  rules: Rules[]
}

export interface Channel extends BaseEntity {
  utmSource: string
  type: ChannelType
  channelCommissions: ChannelCommissions[]
  campaignsCount: number
  adminsCount: number
  campaigns: ChannelCampaign[]
  isBanned: IntBoolean
}

export type PartialChannel = Partial<Channel>;
