
import { ChannelCommissions } from '@/interfaces/Channel';
import dayjs from 'dayjs';
import { defineComponent, PropType, ref, toRefs, watch } from 'vue';

export type UpdateCommissionsEvent = ChannelCommissions[];

export default defineComponent({
  props: {
    isStageAddable: {
      type: Boolean,
      default: null
    },
    channelCommissionsData: {
      type: Array as PropType<ChannelCommissions[]>,
      default: null
    }
  },
  emits: ['update'],
  setup(props, { emit }) {
    const { channelCommissionsData, isStageAddable } = toRefs(props);
    const dialogVisible = ref(false);

    const date = ref();

    const commissionsDataIndex = ref();
    const hasChosenDate = ref(false);
    const isDisabled = ref(false);

    const commissionsData = ref([]);
    const commissionsRulesData = ref([]);

    const openDialog = (row: ChannelCommissions, index: number) => {
      commissionsRulesData.value = row.rules;
      dialogVisible.value = true;
      commissionsDataIndex.value = index;

      if (row.id !== undefined) {
        isDisabled.value = true;
      } else {
        isDisabled.value = false;
      }
    };

    const addChannelCommission = () => {
      commissionsData.value.push({
        startDate: date.value,
        stage: commissionsData.value.length + 1,
        rules: []
      });
    };

    const addChannelRule = (index: number) => {
      commissionsData.value[index].rules.push({
        renewalTimes: commissionsData.value[index].rules.length + 1,
        percentage: 50
      });
    };

    const disabledDate = (datesOnCalendar: any) => {
      if (commissionsData.value.length === 0) {
        const today = new Date();
        return datesOnCalendar.getTime() < today;
      }

      const startDateTimestamp = dayjs(commissionsData.value[commissionsData.value.length - 1].startDate).valueOf();
      const datesTimestamp = datesOnCalendar.getTime();
      const beforeStartDate = datesTimestamp <= startDateTimestamp;

      return beforeStartDate;
    };

    watch(date, () => {
      if (!date.value) return;

      hasChosenDate.value = true;
    });

    watch(channelCommissionsData, () => {
      commissionsData.value = channelCommissionsData.value;

      if (isStageAddable.value) {
        hasChosenDate.value = false;

        emit('update', commissionsData.value);
      }
    }, {
      deep: true,
      immediate: true
    });

    return {
      date,
      commissionsData,
      hasChosenDate,
      dialogVisible,
      isDisabled,
      commissionsDataIndex,
      commissionsRulesData,
      openDialog,
      disabledDate,
      addChannelRule,
      addChannelCommission
    };
  }
});
